import  Bottle  from '../../components/bottle/bottle';
import { useState, useEffect, useRef } from 'react';
import { NavBar } from '../../components/NavBar/navBar';
import './home.css';

export const Home = () => {

    const homeContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const backgroundDiv = homeContainerRef.current;
        if (backgroundDiv) {
          setTimeout(() => {
            (backgroundDiv as HTMLDivElement).style.opacity = '1';
          }, 0);
        }
    }, []);
    
    return (
        <main className=''>
            <div ref={homeContainerRef} className="home-container">
                <NavBar></NavBar>
                <div className='body-container'>
                    <div className='info-container'>
                        <img src="images/alcool_home.webp" alt="description" className='alcool-left' />
                    </div>
                    <Bottle bottleImage="images/bottle_home.webp" translationStyle='accueil' bottleStyle='bottle-face'></Bottle>
                    <div className='right-part'>
                        <div className='description-container'>
                            <h1 className='description-title'>NI DE LA BIÈRE, NI DU CIDRE, C'EST DU HARD CIDER</h1>
                            <p className='description-orange'>NOTRE PROJET ? CRÉER UNE NOUVELLE BOISSON ALCOOLISÉE FESTIVE ET INSPIRÉE DU HARD-CIDER ANGLO-SAXON POUR RÉVOLUTIONNER TES APÉROS</p>
                            <p className='description-orange'>FABRIQUÉE GRÂCE À UNE MÉTHODE DE FERMENTATION PERMETTANT DE TIRER LE MEILLEUR DE CHAQUE INGRÉDIENT, FREIA, C'EST LA NOUVELLE ALTERNATIVE FRUITÉE,
                            PÉTILLANTE ET HYPER GOURMANDE ALLIANT LE GOURMAND DE LA POMME AUX NOTES SUBTILES ET RAFRAÎCHISSANTES DU GINGEMBRE ET DE LA RHUBARBE PROCURANT JOIE ET EXALTATION
                            , C'EST POUR NOUS, POUR VOUS, ET POUR TOUS CEUX QUI CHERCHENT À S'AMUSER TOUT AU LONG DE LA SOIRÉE</p>
                            <p className='signature-orange'>BIENTÔT DANS TON BAR PRÉFÉRÉ POUR PASSER TES MEILLEURES SOIRÉES...</p>
                        </div>
                        <img src="images/Freia_cercle.webp" alt="logo_cercle" className='logo-circle-accueil' />
                    </div>
                </div>
            </div>
        </main>
    )
}