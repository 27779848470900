import './App.css';
import {Navigate, Route, Routes} from 'react-router-dom';
import { Home } from './views/Home/home';
import { TaBoisson } from './views/TaBoisson/taBoisson';
import { Contact } from './views/Contact/contact';

function App() {
  
  return (
      <div className="App">
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/taBoisson' element={<TaBoisson />} />
            <Route path='/contact' element={<Contact />} />

            {/* default redirect to home page */}
            <Route path='/*' element={<Navigate to={'/'}/>} />
        </Routes>
      </div>
  );
}

export default App;