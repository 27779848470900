import React, { ReactNode, MouseEvent } from 'react';
import './FormButton.css';

type ButtonProps = {
    children: ReactNode;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    buttonStyle?: string;
    type?: 'btn-form' | 'btn-age';
};

export const FormButton: React.FC<ButtonProps> = ({
    children,
    onClick, 
    buttonStyle, 
    type
    
}) => {

    return (
            <button
                className={`btn ${type} ${buttonStyle}`}
                onClick={onClick}
            >
                {children}
            </button>
    )
};