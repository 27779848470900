import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { NavButton } from '../navButton/NavButton';
import './navBar.css';

export const NavBar = () => {

    const [theme, setTheme] = useState(() => {
        const currentPath = window.location.pathname;

        if (currentPath === '/taBoisson' || currentPath === '/contact') {
            return 'PURPLE';
        } else {
            return 'ORANGE';
        }
    });

    const [activeButton, setActiveButton] = useState(() => {
        const currentPath = window.location.pathname;

        if (currentPath === '/taBoisson') {
            return 'taBoisson';
        } else if (currentPath === '/contact') {
            return 'contact';
        } else {
            return 'accueil';
        }
    });
    
    const [formType, setFormType] = useState<'PROFESSIONNEL' | 'PARTICULIER' | 'undefined'>('undefined');

    const handleButtonClick = (buttonName: string): void => {
        setActiveButton(buttonName);

        if (buttonName === 'accueil') {
            setTheme('ORANGE');
        } else if (buttonName === 'taBoisson' || buttonName === 'contact') {
            setTheme('PURPLE');
            setFormType('undefined');
        }
    };

    useEffect(() => {}, [theme, activeButton]);


    return (
        <div className='navbar-container'>
            <Link className='nav-div' to='/'>
                { theme === 'PURPLE' &&
                    <img src="images/logo_violet.webp" alt="Logo_Freia" className='logo' />
                }
                { theme === 'ORANGE' &&
                    <img src="images/logo_orange.webp" alt="Logo_Freia" className='logo' />
                }
            </Link>
            <div className='header'>
                <NavButton
                    buttonName='accueil'
                    color={theme}
                    isActive={activeButton === 'accueil'}
                    buttonStyle='nav-button'
                    to="/"
                    onClick={() => {handleButtonClick('accueil')}}
                >
                    ACCUEIL
                </NavButton>
                <NavButton
                    buttonName='taBoisson'
                    color={theme}
                    isActive={activeButton === 'taBoisson'}
                    buttonStyle='nav-button'
                    to="/taBoisson"
                    onClick={() => { handleButtonClick('taBoisson')}}
                >
                    TA BOISSON
                </NavButton>
                <NavButton
                    buttonName='contact'
                    color={theme}
                    isActive={activeButton === 'contact'}
                    buttonStyle='nav-button'
                    to="/contact"
                    onClick={() => { handleButtonClick('contact'); setFormType('undefined') }}
                >
                    CONTACT
                </NavButton>
                <a href='https://www.instagram.com/ramenetafreia/' target="_blank" className='logo-frame'>
                { theme === 'ORANGE' &&
                    <img src='images/insta_orange.webp' alt="insta" className='logo-insta'/>
                }
                { theme === 'PURPLE' &&
                    <img src='images/insta_purple.webp' alt="insta" className='logo-insta'/>
                }
                </a>
            </div>
        </div>
            
    )
}

