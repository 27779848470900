import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './bottle.css';

type bottleProps = {
    bottleImage: string;
    bottleStyle: string;
    descript?: string;
    translationStyle: "accueil" | "taBoisson" | "contact";
};

const DEFAULT_EXPRESSION = "bouteille";

const Bottle = ({ bottleImage, bottleStyle, descript, translationStyle }: bottleProps) => {
    const [isImageVisible, setImageVisible] = useState(true);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setImageVisible(false);
            setOpacity(1);
        }, 0);
        

        return () => clearTimeout(timeout);
    }, []);

    const bottleClass = `bottle ${bottleStyle}`;

    let transitionClass = '';
    if (translationStyle === 'accueil') {
        transitionClass = 'fade-exit-active-accueil';
    } else if (translationStyle === 'taBoisson') {
        transitionClass = 'fade-exit-active-taBoisson';
    } else if (translationStyle === 'contact') {
        transitionClass = 'fade-exit-active-contact';
    }

    const imageStyle = {
        opacity: opacity,
        transition: 'opacity 3000ms, transform 3000ms',
    };

    return (
        <CSSTransition
            in={isImageVisible}
            timeout={2000}
            classNames={`${transitionClass} 'fade' `}
            unmountOnExit={false}
        >
            <img
                src={bottleImage}
                alt={DEFAULT_EXPRESSION}
                className={bottleClass}
                style={imageStyle}

            />
        </CSSTransition>
    );
};

export default Bottle;
