import { useForm, SubmitHandler } from "react-hook-form"
import "./ContactForm.css"
import { FormButton } from "../formButton/FormButton"
import emailjs from "@emailjs/browser"
import { useState } from "react"

type FormInputs = {
    subject?: string,
    email?: string,
    message?: string,
    nom?: string,
    prenom?: string;
    siret?: string;
}

interface ContactFormProps {
    formType: 'undefined' | 'PROFESSIONNEL' | 'PARTICULIER';
    setFormType: (type: 'undefined' | 'PROFESSIONNEL' | 'PARTICULIER') => void;
}

export const ContactForm: React.FC<ContactFormProps> = ({ formType, setFormType }) => {

    const { register, handleSubmit, formState: { errors } } = useForm<FormInputs>();
    const [emailSent, setEmailSent] = useState(false);

    const onSubmit: SubmitHandler<FormInputs> = data => {
        const templateParams = {
            subject: data.subject,
            email: data.email,
            nom: data.nom,
            prenom: data.prenom,
            siret: data.siret,
            message: data.message
        };
        emailjs.send(
            'service_1pvktjb',
            'template_xgb78eq',
            templateParams,
            'UQOWypz0kiLaiVd7e'
        )
            .then(response => {
                console.log('E-mail envoyé avec succès!', response);
                setEmailSent(true); // Définir l'état sur true lorsque l'e-mail est envoyé avec succès
                setTimeout(() => {
                    setEmailSent(false); // Réinitialiser l'état après 5 secondes
                    handleReset();
                }, 5000);
            })
            .catch(error => {
                console.error('Erreur lors de l\'envoi de l\'e-mail:', error);
            });
    };

    const handleReset = () => {
        setFormType('undefined');
    };

    return (
        <main className="main-container">

            <form className="form-frame" onSubmit={handleSubmit(onSubmit)}>
                {formType === 'undefined' &&
                    <div className='type-container'>
                        <h1 className='title-choice'>VOUS ÊTES UN :</h1>
                        <div className='option-container'>
                            <FormButton type='btn-form' buttonStyle="btn-part" onClick={() => { setFormType('PARTICULIER') }} >Particulier</FormButton>
                            <FormButton type='btn-form' buttonStyle="btn-pro" onClick={() => { setFormType('PROFESSIONNEL') }}>Professionnel</FormButton>
                        </div>
                    </div>
                }
                {formType !== 'undefined' &&
                    <div className="form-group">
                        <p className='recipient-mail'>Contact FREIA : contact@freia-drink.fr</p>
                        <div className="form-container">
                            <div className="left-part">
                                <div className="field">
                                    <label className="title" htmlFor="nom">Nom</label>
                                    <input {...register("nom")} className="form-text" type='text' name="nom" id="nom" placeholder='Nom' />
                                </div>
                                <div className="field">
                                    <label className="title" htmlFor="prenom">Prénom</label>
                                    <input {...register("prenom")} className="form-text" type='text' name="prenom" id="prenom" placeholder='Prénom' />
                                </div>
                                {formType === 'PROFESSIONNEL' &&
                                    <div className="field">
                                        <label className="title" htmlFor="siret">SIRET</label>
                                        <input {...register("siret", { required: true })} className="form-text" type='siret' name="siret" id="siret" placeholder='SIRET' />
                                        {errors.email && <p className="text-[red]">SIRET requis</p>}
                                    </div>
                                }
                                <div className="field">
                                    <label className="title" htmlFor="email">Email</label>
                                    <input {...register("email", { required: true })} className="form-text" type='email' name="email" id="email" placeholder='Email' />
                                    {errors.email && <p className="text-[red]">Email requis</p>}
                                </div>
                                <div className="field">
                                    <label className="title" htmlFor="subject">Sujet du mail</label>
                                    <input {...register("subject")} className="form-text" type='text' name="subject" id="subject" placeholder='Quel est le sujet ?' />
                                </div>
                            </div>
                            <div className="message-field">
                                <label className="title" htmlFor="message">Message</label>
                                <textarea {...register("message")} rows={10} className="text-message resize-none" name="message" id="message" placeholder='Votre message' />
                            </div>
                        </div>
                        <div className="choice-container">
                            <FormButton type='btn-form' buttonStyle="submit-button">ENVOYER</FormButton>
                            <FormButton type='btn-form' buttonStyle="reset-button" onClick={handleReset}>RETOUR</FormButton>
                        </div>
                        {emailSent && (
                            <div className="notification">Le mail a été envoyé avec succès !</div>
                        )}
                    </div>
                }
            </form>
        </main>
    )
}
