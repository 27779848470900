import  Bottle  from '../../components/bottle/bottle';
import {  useEffect, useRef } from 'react';
import { NavBar } from '../../components/NavBar/navBar';
import './taBoisson.css';


export const TaBoisson = () => {

    const homeContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const backgroundDiv = homeContainerRef.current;
        if (backgroundDiv) {
          setTimeout(() => {
            (backgroundDiv as HTMLDivElement).style.opacity = '1';
          }, 0);
        }
    }, []);

    return (
        <main className=''>
            <div ref={homeContainerRef} className="boisson-container">
                <NavBar></NavBar>
                <div className='body-container'>
                    <div className='description-container'>
                        <h1 className='description-title'>MON CIDER EST UNE FREIACHEUR</h1>
                        <p className='description-boisson1'>INGRÉDIENTS FRANÇAIS</p>
                        <p className='description-boisson2'>PRODUITE EN FRANCE</p>
                        <p className='description-boisson3'>INSPIRÉE DU HARD CIDER ANGLO-SAXON</p>                                
                        <p className='description-boisson2'>RECETTE UNIQUE AU GOÛT DU JOUR</p>
                        <p className='description-boisson4'>SANS GLUTEN, SANS LACTOSE ET SANS SUCRE*</p>
                        <p className='description-boisson1'>FRUITÉE, PÉTILLANTE, GOURMANDE</p>
                    </div>
                    <Bottle bottleImage="images/bottle_taBoisson.webp" translationStyle='taBoisson' bottleStyle='bottle-back'></Bottle>
                    <div className='border-container'>
                       
                            <img src="images/alcool_violet.webp" alt="description" className='alcool-right'/>
                            <img src="images/Freia_cercle.webp" alt="logo_cercle" className='logo-circle' />
                        
                    </div>
                </div>
            </div>
        </main>
    )
}