import  Bottle  from '../../components/bottle/bottle';
import { useState, useEffect, useRef } from 'react';
import { ContactForm } from '../../components/contactForm/ContactForm';
import { NavBar } from '../../components/NavBar/navBar';
import './contact.css';

export const Contact = () => {

    const [formType, setFormType] = useState<'PROFESSIONNEL' | 'PARTICULIER' | 'undefined'>('undefined');
    const homeContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const backgroundDiv = homeContainerRef.current;
        if (backgroundDiv) {
          setTimeout(() => {
            (backgroundDiv as HTMLDivElement).style.opacity = '1';
          }, 0);
        }
    }, []);

    return (
        <main className=''>
                <div ref={homeContainerRef} className="contact-container">
                    <NavBar></NavBar>
                    <div className='contact'>
                        <div className='info-container'>
                            <img src="images/alcool_violet.webp" alt="description" className='alcool-left' />
                        </div>
                        <Bottle bottleImage="images/bottle_triple.webp" translationStyle='contact' bottleStyle='bottle-triple'></Bottle>
                        <div className='form-container'>
                            <ContactForm setFormType={setFormType} formType={formType}/>
                        </div>
                    </div>
                </div>
        </main>
    )
}