import React, { ReactNode, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import './NavButton.css';

type ButtonProps = {
    children: ReactNode;
    onClick?: () => void;
    buttonStyle?: string;
    buttonName: string;
    isActive?: Boolean;
    color: string;
    to: string
};

export const NavButton: React.FC<ButtonProps> = ({
    children,
    buttonStyle,
    isActive = false,
    onClick,
    color,
    to,
    buttonName,
}) => {
    const checkButtonStyle = buttonStyle ? buttonStyle : "";
    const activeButtonClass = isActive ? "active-button" : "";
    const checkColor = (value: String) => {
        
        if( value === 'ORANGE') {
            return 'btn-orange';
        } else if ( value === 'PURPLE' ) {
            return 'btn-purple';
        }
    } 

    return (
        <Link
            to={to}
            className={`btn ${checkButtonStyle} btn-style ${activeButtonClass} ${checkColor(color)}`}
            onClick={onClick}
        >
            <ul className="btn-container">
                <li>{children}</li>
                <li className='nav-dot'>
                    <i className='fa-solid fa-circle nav-dot'></i>
                </li>
            
            </ul>
        </Link>

    );
};